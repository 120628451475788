import { useState } from "react";
import { Modal, Rate, Input } from "antd";
const { TextArea } = Input;
import netsuiteLib from "@/lib/netsuitelib";

const SurveyModal = (props) => {

    const maxAdditionalInfoLength = 300; // max length of the Free-Form Text NetSuite field type
    const [rateValue, setRateValue] = useState();
    const [additionalInfoValue, setAdditionalInfo] = useState();
    const [additionalInfoLength, setAdditionalInfoLength] = useState("0/" + maxAdditionalInfoLength);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const handleOk = async() => {
        if (rateValue) {
            setConfirmLoading(true);
            var response = await netsuiteLib.postSurveyResponse({rating: rateValue, additionalInfo: additionalInfoValue});
            if (response.success) {
                await netsuiteLib.logSurveySubmitted(response.result);
            }
            props.hideSurvey();
        }
    };

    const handleCancel = async() => {
        props.hideSurvey();
        await netsuiteLib.logSurveyCancelled();
    };

    const rateValueChanged = (newValue) => {
        setRateValue(newValue);
    }

    const additionalInfoValueChanged = (e) => {
        setAdditionalInfo(e.target.value);
        setAdditionalInfoLength(e.target.value.length + "/" + maxAdditionalInfoLength);
    }

    return (
        <Modal 
            title="User Survey" 
            closable={false}
            open={props.open} 
            cancelText={"Maybe Later"} 
            okText={"Submit"} 
            onOk={handleOk} 
            onCancel={handleCancel} 
            confirmLoading={confirmLoading}
            okButtonProps={{disabled: !rateValue}}
            cancelButtonProps={{disabled: confirmLoading}}
            centered>
                <div style={{display: confirmLoading ? "none" : "block"}}>
                    <p>How would you rate your Element Critical customer portal experience?</p>
                    <div style={{textAlign: "center"}}>
                        <Rate onChange={rateValueChanged} value={rateValue} style={{fontSize: "30px"}}/>
                    </div>
                    <p>
                        <span>Additional Feedback</span>
                        <span style={{fontSize: "11px"}}> (optional)</span>
                    </p>
                    <TextArea onChange={additionalInfoValueChanged} value={additionalInfoValue} rows={2} maxLength={maxAdditionalInfoLength} />
                    <div style={{fontSize: "11px"}}>{additionalInfoLength}</div>
                </div>
                <div style={{display: confirmLoading ? "block" : "none", fontSize: "30px", textAlign: "center", padding: "75px 50px"}}>
                    Thank You!
                </div>
        </Modal>
    )
}
export default SurveyModal