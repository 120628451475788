import axios from "axios";
import events from "./userEvents";

const GET = "get";
const POST = "post";
const PUT = "put";
const DELETE = "delete";

const netsuiteLib = {};

netsuiteLib.getCases = async function() {
    return await post(process.env.NEXT_PUBLIC_CASES_SCRIPT_ID, process.env.NEXT_PUBLIC_CASES_DEPLOYMENT_ID);
}

netsuiteLib.getCase = async function(data) {
    return await get(process.env.NEXT_PUBLIC_CASES_SCRIPT_ID, process.env.NEXT_PUBLIC_CASES_DEPLOYMENT_ID, data);
}

netsuiteLib.getInvoices = async function() {
    return await post(process.env.NEXT_PUBLIC_INVOICES_SCRIPT_ID, process.env.NEXT_PUBLIC_INVOICES_DEPLOYMENT_ID);
}

netsuiteLib.getInvoice = async function(data) {
    return await get(process.env.NEXT_PUBLIC_INVOICES_SCRIPT_ID, process.env.NEXT_PUBLIC_INVOICES_DEPLOYMENT_ID, data);
}

netsuiteLib.getPortalUsers = async function() {
    return await get(process.env.NEXT_PUBLIC_PORTAL_USERS_SCRIPT_ID, process.env.NEXT_PUBLIC_PORTAL_USERS_DEPLOYMENT_ID);
}

netsuiteLib.getContacts = async function() {
    return await get(process.env.NEXT_PUBLIC_PORTAL_CONTACTS_SCRIPT_ID, process.env.NEXT_PUBLIC_PORTAL_CONTACTS_DEPLOYMENT_ID);
}

netsuiteLib.getContact = async function(data) {
    return await get(process.env.NEXT_PUBLIC_PORTAL_CONTACTS_SCRIPT_ID, process.env.NEXT_PUBLIC_PORTAL_CONTACTS_DEPLOYMENT_ID, data);
}

netsuiteLib.updatePortalPermissions = async function(data) {
    return await post(process.env.NEXT_PUBLIC_PERMISSIONS_UPDATE_SCRIPT_ID, process.env.NEXT_PUBLIC_PERMISSIONS_UPDATE_DEPLOYMENT_ID, data);
}

netsuiteLib.getSurveyStatus = async function() {
    return await get(process.env.NEXT_PUBLIC_USER_SURVEY_SCRIPT_ID, process.env.NEXT_PUBLIC_USER_SURVEY_DEPLOYMENT_ID);
}

netsuiteLib.postSurveyResponse = async function(data) {
    return await post(process.env.NEXT_PUBLIC_USER_SURVEY_SCRIPT_ID, process.env.NEXT_PUBLIC_USER_SURVEY_DEPLOYMENT_ID, data);
}

netsuiteLib.logUserLogin = async function() {
    return await logPortalUserAction({event: events.login});
}

netsuiteLib.logUserLogout = async function() {
    return await logPortalUserAction({event: events.logout});
}

netsuiteLib.logCasesView = async function() {
    return await logPortalUserAction({event: events.casesView});
}

netsuiteLib.logCaseCreate = async function(caseId) {
    return await logPortalUserAction({event: events.caseCreate, caseId: caseId});
}

netsuiteLib.logCaseView = async function(caseData) {
    return await logPortalUserAction({event: events.caseView, caseId: caseData.id, caseNumber: caseData.caseNumber});
}

netsuiteLib.logCaseUpdate = async function(caseData) {
    return await logPortalUserAction({event: events.caseUpdate, caseId: caseData.id, caseNumber: caseData.caseNumber});
}

netsuiteLib.logContactsView = async function() {
    return await logPortalUserAction({event: events.contactsView});
}

netsuiteLib.LogContactCreate = async function(contactId, contactData, permissionsData, locations) {
    return await logPortalUserAction({event: events.conctactCreate, contactId: contactId, contactData: contactData, 
        permissionsData: permissionsData, locations: locations});
}

netsuiteLib.LogContactView = async function(contact) {
    return await logPortalUserAction({event: events.contactView, contactId: contact.userId, contactName: contact.fullName});
}

netsuiteLib.logContactUpdate = async function(existingContactData, updatedContactData, updatedPermissionsData, locations) {
    return await logPortalUserAction({event: events.contactUpdate, existingContactData: existingContactData, 
        updatedContactData: updatedContactData, updatedPermissionsData: updatedPermissionsData, locations: locations});
}

netsuiteLib.logLocationAccessRevoked = async function(contact, location) {
    return await logPortalUserAction({event: events.contactLocationRevoked, locationName: location.locationName, 
        contactId: contact.userId, contactName: contact.fullName});
}

netsuiteLib.logInvoicesView = async function() {
    return await logPortalUserAction({event: events.invoicesView});
}

netsuiteLib.logInvoiceView = async function(invoice) {
    return await logPortalUserAction({event: events.invoiceView, invoiceId: invoice.internalid, invoiceName: invoice.name});
}

netsuiteLib.logInvoiceDownload = async function(internalid, invoiceName) {
    return await logPortalUserAction({event: events.invoiceDownload, invoiceName: invoiceName, internalid: internalid});
}

netsuiteLib.logContractsView = async function() {
    return await logPortalUserAction({event: events.contractsView});
}

netsuiteLib.logContractView = async function(contract) {
    return await logPortalUserAction({event: events.contractView, contractId: contract.contractId, contractName: contract.contractName});
}

netsuiteLib.logCabinetView = async function(cabinet) {
    return await logPortalUserAction({event: events.cabinetView, cabinetId: cabinet.id, cabinetName: cabinet.name});
}

netsuiteLib.logCircuitView = async function(power) {
    return await logPortalUserAction({event: events.powerView, powerId: power.id, powerName: power.name});
}

netsuiteLib.logCrossConnectView = async function(crossConnect) {
    return await logPortalUserAction({event: events.crossConnectView, crossConnectId: crossConnect.id, crossConnectName: crossConnect.name});
}

netsuiteLib.logSiteAccessView = async function() {
    return await logPortalUserAction({event: events.siteAccessView});
}

netsuiteLib.logComplianceView = async function() {
    return await logPortalUserAction({event: events.complianceView});
}

netsuiteLib.logComplianceDocumentDownload = async function(pdfName) {
    return await logPortalUserAction({event: events.complianceDocumentDownload, pdfName: pdfName});
}

netsuiteLib.logGuidesView = async function() {
    return await logPortalUserAction({event: events.guidesView});
}

netsuiteLib.logGuideDownload = async function(fileName) {
    return await logPortalUserAction({event: events.guidesDocumentDownload, fileName: fileName});
}

netsuiteLib.logSurveySubmitted = async function(surveyResponseId) {
    return await logPortalUserAction({event: events.surveySubmitted, surveyResponseId: surveyResponseId});
}

netsuiteLib.logSurveyCancelled = async function() {
    return await logPortalUserAction({event: events.surveyCancelled});
}

const logPortalUserAction = async function(data) {
    return await post(process.env.NEXT_PUBLIC_USER_EVENT_SCRIPT_ID, process.env.NEXT_PUBLIC_USER_EVENT_DEPLOYMENT_ID, data);
}

const get = async function(scriptId, deploymentId, data) {
    return await restletRequest(GET, scriptId, deploymentId, data);
}

const post = async function(scriptId, deploymentId, data) {
    return await restletRequest(POST, scriptId, deploymentId, data);
}

const put = async function(scriptId, deploymentId, data) {
    return await restletRequest(PUT, scriptId, deploymentId, data);
}

const doDelete = async function(scriptId, deploymentId, data) {
    return await restletRequest(DELETE, scriptId, deploymentId, data);
}

const restletRequest = async function(method, scriptId, deploymentId, data, start, end) {

    method = method.toLowerCase();

    start = start ? start : 0;
    end = end ? end : Number(process.env.NEXT_PUBLIC_MAX_NETSUITE_RECORDS);

    if (start < 0 || end < 1 || start >= end) {
        console.error("Start range value must be greater than or equal to 0 and less than End range value.");
        return;
    }

    const max = end - start;
    const increment = max + 1;

    // Verify required parameters
    if (!scriptId) {
        console.error("NetSuite scriptId is required.");
        return;
    }
    if (!deploymentId) {
        console.error("NetSuite script deploymentId is required.");
        return;
    }
    if (data && (method == GET || method == DELETE)) {
        if (typeof data != 'object' || data.constructor.name == "Array") {
            console.error("HTTP method type '" + method + "' parameters must be object key/value pairs.");
            return;
        }
    }
    // Commenting this out for now as some requests gather data server-side
    // if (!data && (method == POST || method == PUT)) {
    //     console.error("Data required for HTTP method type: '" + method + "'");
    //     return;
    // }

    let responseData = await axios.post('/api/netsuite', {method: method, scriptId: scriptId, deploymentId: deploymentId, data: data, start: start, end: end})
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });

    if (responseData && responseData.success && responseData.results?.length == max) {
        start += increment;
        end += increment;
        
        const moreData = await restletRequest(method, scriptId, deploymentId, data, start, end);

        if (moreData.success) {
            responseData.results.push(...moreData.results);
        }
    }

    return responseData;
}

export default netsuiteLib;